import React, {
  useContext, useEffect, useState,
} from 'react';
import {
  Table, Form, Button, notification,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  CheckCircleOutlined, EditOutlined, EyeOutlined, StopOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  formatDateOrNA,
  getFieldValue,
  getStatusClass,
  getStatusText,
  orderActionButtons,
  regexMobileNumber,
} from '../../utils/utils';
import EditableCell from '../shared/EditableCell';
import { getOrdersAction, patchOrderDetailsAction } from '../../business/actions/orderAction';
import TableTemplate from '../shared/TableTemplate';
import ActionButton from '../shared/ActionButton';
import { AuthContext } from '../../utils/context/AuthContext';
import { getDistributorsAction } from '../../business/actions/distributorAction';
import { patchPhoneNumberAction, patchPhoneNumberStatusAction } from '../../business/actions/mobileAction';

const OrdersList = ({
  setIsOpenDrawer, setTypeDrawer, setCurrentStatus, setCurrentOrder, getLatestOrderStatus,
}) => {
  const { distributors } = useSelector((state) => state.distributors);
  const { companies } = useSelector((state) => state.companies);
  const { orders } = useSelector((state) => state.orders);
  const { mobiles } = useSelector((state) => state.mobiles);
  const { agencies } = useSelector((state) => state.agencies);
  const [tableData, setTableData] = useState([]);
  const { token, role } = useContext(AuthContext);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [editingSubKey, setEditingSubKey] = useState('');
  const isEditingPrimaryRow = (record) => record.key === editingKey;
  const isEditingSubRow = (record) => record.key === editingSubKey;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const orderButtons = orderActionButtons(t);

  useEffect(() => {
    if (!orders) {
      dispatch(getOrdersAction.request({ token, role }));
      dispatch(getDistributorsAction.request({ token, role }));
    }
  }, [mobiles]);

  useEffect(() => {
    setCurrentStatus();
  }, []);

  const renderData = () => {
    if (orders && orders.length > 0) {
      const data = orders.map((order) => {
        const orderHasStatus = order.status.length > 0;
        const latestStatus = orderHasStatus && getLatestOrderStatus(order.status);
        const orderIsAbandoned = order.status.some((elt) => elt.status === 100);
        const renderStatus = orderIsAbandoned ? 100 : latestStatus;
        const company = companies?.find((item) => item.id === order.companyId);
        const distributor = distributors?.find((item) => item.id === company?.distributorId);

        const licenceList = order.licences.map((licence) => {
          const currentMobile = mobiles?.find((item) => item.telephoneNumber === licence.number);
          const currentHolder = agencies?.find((item) => item.id === currentMobile?.agencyId)?.name;
          return ({
            key: licence.number,
            holder: currentHolder || order.companyName,
            number: licence.number,
            statusCode: licence.status,
          });
        });

        return {
          key: order.id,
          distributor: distributor?.name || '--',
          name: order.name || '--',
          companyName: order.companyName,
          licenceLimit: order.licenceLimit,
          quoteNumber: order.quoteNumber,
          totalAmount: order.totalAmount,
          orderNumber: order.orderNumber || '--',
          invoiceNumber: order.invoiceNumber || '--',
          duration: order.renewalDuration,
          startDate: order.startTimestamp,
          expiryDate: order.expiryTimestamp,
          status: renderStatus,
          licenceList,
          telephoneNumber: order.telephoneNumber,
        };
      });
      setTableData(data);
    }
  };
  useEffect(() => {
    renderData();
  }, [orders, mobiles, distributors]);

  const handleOpenDrawer = ({ status, item }) => {
    setIsOpenDrawer(true);
    setTypeDrawer('status');
    setCurrentStatus(status);
    setCurrentOrder(item.key);
  };

  const editPrimaryRow = (record) => {
    const durationValue = parseInt(record.duration, 10);
    form.setFieldsValue({
      name: record.name,
      licenceLimit: record.licenceLimit,
      totalAmount: record.totalAmount,
      orderNumber: getFieldValue(record.orderNumber),
      quoteNumber: getFieldValue(record.quoteNumber),
      invoiceNumber: getFieldValue(record.invoiceNumber),
      duration: durationValue,
      startDate: record.startDate ? dayjs(record.startDate) : 0,
      expiryDate: record.expiryDate ? dayjs(record.expiryDate) : 0,
    });
    setEditingKey(record.key);
    setEditingSubKey('');
  };

  const editSubRow = (record) => {
    form.setFieldsValue({
      number: record.number,
    });
    setEditingSubKey(record.key);
    setEditingKey('');
  };

  const cancel = () => {
    setEditingKey('');
    setEditingSubKey('');
  };

  const save = async (key, isSubRow = false, parentKey = null) => {
    const row = await form.validateFields();
    const newData = [...tableData];

    if (isSubRow) {
      const parentIndex = newData.findIndex((item) => parentKey === item.key);
      if (parentIndex > -1) {
        const parentItem = newData[parentIndex];
        const subIndex = parentItem.licenceList.findIndex((item) => key === item.number);
        if (subIndex > -1) {
          const item = parentItem.licenceList[subIndex];
          const isValidMobileNumber = regexMobileNumber.test(row.number);
          if (!isValidMobileNumber) {
            notification.warning({
              description: t('shared.formTemplate.invalidMobileNumber'),
            });
          } else {
            parentItem.licenceList.splice(subIndex, 1, { ...item, ...row });
            newData[parentIndex] = parentItem;
            const mobile = mobiles.find((m) => m.telephoneNumber === item.number);

            dispatch(patchPhoneNumberAction.request({
              telephoneNumber: row.number,
              id: mobile.id,
              androidId: mobile.androidId,
              systemVersion: mobile.systemVersion,
              model: mobile.model,
              token,
            }));
            setTableData(newData);
            setEditingSubKey('');
          }
        }
      }
    } else {
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        const startTimestamp = form.getFieldValue('startDate').valueOf();
        const expiryTimestamp = form.getFieldValue('expiryDate').valueOf();
        const isValidTimestamp = startTimestamp !== 0 && expiryTimestamp !== 0;

        if (isValidTimestamp && startTimestamp >= expiryTimestamp) {
          notification.warning({
            message: t('shared.notification.error'),
            description: t('shared.formTemplate.startDateMustBeLessThanExpiryDate'),
          });
        } else {
          newData.splice(index, 1, {
            ...item,
            ...row,
            startTimestamp,
            expiryTimestamp,
            id: item.key,
          });
          setTableData(newData);
          setEditingKey('');

          dispatch(patchOrderDetailsAction.request({
            licenceLimit: row.licenceLimit,
            name: row.name,
            totalAmount: row.totalAmount,
            orderNumber: row.orderNumber,
            quoteNumber: row.quoteNumber,
            invoiceNumber: row.invoiceNumber,
            duration: row.duration,
            startTimestamp,
            expiryTimestamp,
            id: item.key,
            token,
          }));
        }
      } else {
        newData.push(row);
        setTableData(newData);
        setEditingKey('');
      }
    }
  };

  const renderOrderButtons = (record) => (
    orderButtons.map((button) => (
      <ActionButton
        key={button.id}
        title={button.title}
        icon={button.icon}
        disabled={editingKey !== '' || editingSubKey !== ''}
        onClick={() => {
          if (button.id === 10) {
            editPrimaryRow(record);
          }
        }}
      />
    ))
  );

  const handleToggleLicenceStatus = (status, licence) => {
    const mobile = mobiles?.find((item) => item.telephoneNumber === licence.number);
    const newStatus = status === 0 ? 1 : 0;

    dispatch(patchPhoneNumberStatusAction.request({ status: newStatus, id: mobile.id, token }));
  };

  const renderSubRowButtons = (licence) => {
    const { licenceStatus } = licence;

    const title = licenceStatus === 0 ? t('orders.block') : t('orders.activate');
    const icon = licenceStatus === 0 ? <StopOutlined className="red" /> : <CheckCircleOutlined className="green" />;

    return (
      <>
        <ActionButton
          title={title}
          icon={icon}
          onClick={() => handleToggleLicenceStatus(licenceStatus, licence)}
        />
        <ActionButton
          title={t('orders.edit')}
          icon={<EditOutlined className="blue-azure" />}
          disabled={editingKey !== '' || editingSubKey !== ''}
          onClick={() => editSubRow(licence)}
        />
      </>
    );
  };

  const getInputType = (dataIndex) => {
    switch (dataIndex) {
      case 'licenceLimit':
      case 'totalAmount':
      case 'duration':
        return 'number';
      case 'startDate':
      case 'expiryDate':
        return 'date';
      default:
        return 'text';
    }
  };

  const expandedRowRender = (parentRecord) => {
    const subColumns = [
      {
        title: t('orders.holder'),
        key: 'holder',
        dataIndex: 'holder',
        responsive: ['md', 'lg'],
        width: 150,
        align: 'center',
      },
      {
        title: t('orders.telephoneNumber'),
        key: 'telephoneNumber',
        dataIndex: 'number',
        responsive: ['md', 'lg'],
        width: 150,
        align: 'center',
        editable: true,
      },
      {
        title: t('orders.status'),
        key: 'statusCode',
        dataIndex: 'statusCode',
        responsive: ['md', 'lg'],
        width: 100,
        align: 'center',
        render: (status) => (
          <span className={getStatusClass(status)}>
            {getStatusText(status, t)}
          </span>
        ),
      },
      {
        title: t('orders.action'),
        key: 'action',
        dataIndex: 'action',
        responsive: ['md', 'lg'],
        width: 150,
        align: 'center',
        render: (_, record) => {
          const editable = isEditingSubRow(record);
          return editable ? (
            <>
              <Button
                type="primary"
                ghost
                className="button-reset"
                onClick={() => save(record.key, true, parentRecord.key)}
              >
                {t('shared.formTemplate.submit')}
              </Button>
              <Button danger className="button-reset" onClick={cancel}>
                {t('shared.formTemplate.cancel')}
              </Button>
            </>
          ) : (
            renderSubRowButtons(record)
          );
        },
      },
    ];

    const mergedSubColumns = subColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditingSubRow(record),
        }),
      };
    });

    return (
      <Table
        columns={mergedSubColumns}
        dataSource={parentRecord.licenceList}
        pagination={false}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
      />
    );
  };

  const primaryColumns = [
    {
      title: t('orders.distributor'),
      key: 'distributor',
      dataIndex: 'distributor',
      responsive: ['md', 'lg'],
      width: 80,
      align: 'center',
    },
    {
      title: t('orders.form.orderName'),
      key: 'name',
      dataIndex: 'name',
      responsive: ['md', 'lg'],
      width: 80,
      align: 'center',
      editable: true,
    },
    {
      title: t('orders.companyName'),
      key: 'companyName',
      dataIndex: 'companyName',
      responsive: ['md', 'lg'],
      width: 120,
      align: 'center',
    },
    {
      title: t('orders.licenceLimit'),
      key: 'licenceLimit',
      dataIndex: 'licenceLimit',
      responsive: ['md', 'lg'],
      width: 80,
      align: 'center',
      editable: true,
    },
    {
      title: t('orders.quoteNumber'),
      key: 'quoteNumber',
      dataIndex: 'quoteNumber',
      responsive: ['md', 'lg'],
      width: 120,
      align: 'center',
      editable: true,
    },
    {
      title: t('orders.totalAmount'),
      key: 'totalAmount',
      dataIndex: 'totalAmount',
      responsive: ['md', 'lg'],
      width: 80,
      align: 'center',
      editable: true,
    },
    {
      title: t('orders.orderNumber'),
      key: 'orderNumber',
      dataIndex: 'orderNumber',
      responsive: ['md', 'lg'],
      width: 120,
      align: 'center',
      editable: true,
    },
    {
      title: t('orders.invoiceNumber'),
      key: 'invoiceNumber',
      dataIndex: 'invoiceNumber',
      responsive: ['md', 'lg'],
      width: 120,
      align: 'center',
      editable: true,
    },
    {
      title: t('orders.duration'),
      key: 'duration',
      dataIndex: 'duration',
      responsive: ['md', 'lg'],
      width: 60,
      align: 'center',
      editable: true,
    },
    {
      title: t('orders.startDate'),
      key: 'startDate',
      dataIndex: 'startDate',
      render: (date) => formatDateOrNA(date, t('format.date')),
      responsive: ['md', 'lg'],
      width: 100,
      align: 'center',
      editable: true,
    },
    {
      title: t('orders.expiryDate'),
      key: 'expiryDate',
      dataIndex: 'expiryDate',
      render: (date) => formatDateOrNA(date, t('format.date')),
      responsive: ['md', 'lg'],
      width: 100,
      align: 'center',
      editable: true,
    },
    {
      title: t('orders.status'),
      key: 'status',
      dataIndex: 'status',
      responsive: ['md', 'lg'],
      width: 75,
      align: 'center',
      render: (status, item) => (
        <>
          <p className={getStatusClass(status)}>
            {getStatusText(status, t)}
          </p>
          <EyeOutlined onClick={() => handleOpenDrawer({ status, item })} />
        </>
      ),
    },
    {
      title: t('orders.action'),
      key: 'action',
      dataIndex: 'action',
      responsive: ['md', 'lg'],
      width: 150,
      align: 'center',
      render: (_, record) => {
        const editable = isEditingPrimaryRow(record);
        return editable ? (
          <>
            <Button
              type="primary"
              ghost
              className="button-reset"
              onClick={() => save(record.key)}
            >
              {t('shared.formTemplate.submit')}
            </Button>
            <Button danger className="button-reset" onClick={cancel}>
              {t('shared.formTemplate.cancel')}
            </Button>
          </>
        ) : (
          renderOrderButtons(record)
        );
      },
    },
  ];

  const mergedColumns = primaryColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => {
        const inputType = getInputType(col.dataIndex);
        return {
          record,
          inputType,
          dataIndex: col.dataIndex,
          title: col.title,
          children: col.dataIndex,
          editing: isEditingPrimaryRow(record),
        };
      },
    };
  });

  return (
    <Form form={form} component={false}>
      <TableTemplate
        columns={mergedColumns}
        expandable={{
          expandedRowRender,
        }}
        typeList="fullHeight"
        data={tableData}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
      />
    </Form>
  );
};

OrdersList.propTypes = {
  setIsOpenDrawer: PropTypes.func,
  setTypeDrawer: PropTypes.func,
  setCurrentStatus: PropTypes.func,
  setCurrentOrder: PropTypes.func,
  getLatestOrderStatus: PropTypes.func.isRequired,
};

OrdersList.defaultProps = {
  setIsOpenDrawer: () => {},
  setTypeDrawer: () => {},
  setCurrentStatus: () => {},
  setCurrentOrder: () => {},
};

export default OrdersList;
