import { put, takeEvery, call } from 'redux-saga/effects';
import { AUTH, postAuthAction } from '../actions/authAction';
import postAuthService from '../services/authService';

export function* postAuth({ payload }) {
  try {
    const token = yield call(postAuthService, payload);
    if (token) {
      localStorage.setItem('authToken', token.access_token);
      localStorage.setItem('refreshToken', token.refresh_token);
      yield put(postAuthAction.success(token));
    }
  } catch (err) {
    yield put(postAuthAction.failure(err));
  }
}

export function* postAuthSaga() {
  yield takeEvery(AUTH.POST.REQUEST, postAuth);
}
