import axios from 'axios';
import { isProdEnv } from '../../utils/utils';
import { handleSagaErrorNotification } from './responseHandler';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((response) => {
  const token = localStorage.getItem('authToken');
  if (token) {
    response.headers.Authorization = `Bearer ${token}`;
  }
  return response;
}, (error) => {
  Promise.reject(error);
});

axiosInstance.interceptors.response.use((response) => response, (error) => {
  const { response, config } = error;
  const { url } = config;
  if (!isProdEnv) {
    if (response && response.status === 401) {
      handleSagaErrorNotification(error, url);
    }
  }
  if (response && response.status === 403) {
    handleSagaErrorNotification(error, url);
  }
  if (response && response.status === 500) {
    handleSagaErrorNotification(error, url);
  }

  return Promise.reject(error);
});

export default axiosInstance;
