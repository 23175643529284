/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Button, DatePicker, Form, Input, Select, Tag,
} from 'antd';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

const FormTemplate = (props) => {
  const {
    nameForm,
    className,
    rangeClassName,
    variables,
    useForm,
    buttonValidText,
    onFinishApiCall,
    submitDisabled,
    onValuesChange,
    layout,
    initialValues,
  } = props;
  const formLayout = layout || 'vertical';
  const { Password } = Input;
  const { t } = useTranslation();

  const renderInput = ({
    placeholder,
    type,
    prefix,
    sufix,
    selectValues,
    onChange,
    minLength,
    maxLength,
    min,
    max,
    list,
    setList,
    onClick,
    isDisabledBtn,
    onBeforeInput,
  }) => {
    const handleRemoveToList = (item) => {
      const newList = list.filter((tag) => tag !== item);
      setList(newList);
    };

    const renderTagsList = () => list.map((item) => (
      <Tag
        closable
        onClose={() => handleRemoveToList(item)}
        key={item}
      >
        {item}
      </Tag>
    ));

    if (type === 'select') {
      return <Select placeholder={placeholder} options={selectValues} />;
    }
    if (type === 'password') {
      return (
        <Password
          prefix={prefix}
          suffix={sufix}
          placeholder={placeholder}
        />
      );
    }
    if (type === 'rangeDate') {
      const { RangePicker } = DatePicker;
      return <RangePicker className={rangeClassName} />;
    }

    if (type === 'addItem') {
      return (
        <div>
          {list && renderTagsList()}
          <Input
            prefix={prefix}
            className="input-add-to-list"
            placeholder={placeholder}
            autoComplete="off"
            type={type}
            onChange={onChange}
            maxLength={maxLength}
            minLength={minLength}
          />
          <Button
            type="primary"
            className="btn-add-to-list"
            disabled={isDisabledBtn}
            onClick={onClick}
          >
            {t('shared.formTemplate.addBtn')}
          </Button>
        </div>
      );
    }
    return (
      <Input
        className="form-input"
        prefix={prefix}
        max={max}
        min={min}
        placeholder={placeholder}
        autoComplete="off"
        type={type}
        onChange={onChange}
        maxLength={maxLength}
        onBeforeInput={onBeforeInput}
      />
    );
  };

  return (
    <div className="form-container">
      <div className="form-block">
        <Form
          layout={formLayout}
          name={nameForm}
          labelAlign="top"
          requiredMark
          colon={false}
          initialValues={{ remember: true, ...initialValues }}
          onFinish={onFinishApiCall}
          className={className}
          form={useForm}
          onValuesChange={onValuesChange}
        >
          {variables?.map((variable) => {
            if (!variable.noUpdate) {
              return (
                <Form.Item
                  key={variable.name}
                  label={variable.label}
                  extra={variable.extra}
                  name={variable.name}
                  hasFeedback
                  validateDebounce={variable.validateDebounce}
                  rules={variable.rules}
                  help={variable.help}
                  validateTrigger={variable.isDisabledValidateTrigger ? undefined : ['onChange', 'onBlur']}
                >
                  {renderInput(variable)}
                </Form.Item>
              );
            } return null;
          })}
          { !submitDisabled && (
          <Form.Item key="As--32" className="submit" name="validate">
            <Button type="primary" htmlType="submit">
              {buttonValidText}
            </Button>
          </Form.Item>
          ) }
        </Form>
      </div>
    </div>

  );
};

FormTemplate.propTypes = {
  nameForm: PropTypes.string.isRequired,
  className: PropTypes.string,
  rangeClassName: PropTypes.string,
  variables: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  useForm: PropTypes.shape().isRequired,
  buttonValidText: PropTypes.string,
  submitDisabled: PropTypes.bool,
  onFinishApiCall: PropTypes.func,
  initialValues: PropTypes.shape({}),
  layout: PropTypes.string,
  onValuesChange: PropTypes.func,
};

FormTemplate.defaultProps = {
  layout: 'vertical',
  className: '',
  rangeClassName: '',
  onFinishApiCall: () => {},
  buttonValidText: '',
  initialValues: {},
  submitDisabled: false,
  onValuesChange: () => {},
};

export default FormTemplate;
