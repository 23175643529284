import { jwtDecode } from 'jwt-decode';
import { getExpFromToken } from '../../utils/utils';
import { postAuthAction } from '../actions/authAction';
import postRefreshService from '../services/refreshService';

const tokenMiddleware = (storeAPI) => (next) => async (action) => {
  if (action.type.endsWith('_REQUEST')) {
    const state = storeAPI.getState();
    const token = state.auth?.token;
    const { payload } = action;
    const { accessExpired } = getExpFromToken(jwtDecode(token.access_token));

    if (token && !accessExpired) {
      payload.headers = {
        ...action.payload.headers,
        Authorization: `Bearer ${token.access_token}`,
      };
    }
    if (token && accessExpired) {
      const accessStorage = localStorage.getItem('authToken');
      const refreshStorage = localStorage.getItem('refreshToken');
      try {
        const newToken = await postRefreshService({
          accessToken: accessStorage,
          refreshToken: refreshStorage,
        });
        localStorage.setItem('authToken', newToken.access_token);
        localStorage.setItem('refreshToken', newToken.refresh_token);

        storeAPI.dispatch(postAuthAction.success(newToken));

        payload.headers = {
          ...action.payload.headers,
          Authorization: `Bearer ${newToken.access_token}`,
        };
      } catch (error) {
        storeAPI.dispatch(postAuthAction.failure(error));
      }
    }
  }
  return next(action);
};

export default tokenMiddleware;
